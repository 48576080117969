const AppColors = {
  PRIMARY: '#3656ff',

  SECONDARY: '#FFFFFF',
  LIGHT_GREY_BB: '#bbbbbb',
  LIGHT_GREY_EE: '#eeeeee',
  DARK_GREY_77: '#777777',
  DARK_GREY_33: '#333333',

  ITEM_BORDER: '#cccccc',

  // TODO: 위 COLOR는 제거 대상 임
  PRIMARY_BLUE: 'rgba(66,106,255,100%)',
  MEDIUM_PRIMARY_BLUE: 'rgba(66,106,255,50%)',
  MEDIUM_DARK_PRIMARY_BLUE: '#3350D4',
  LIGHT_PRIMARY_BLUE: 'rgba(66,106,255,15%)',
  PRIMARY_BLUE_FOR_SELECTION: 'rgba(66,106,255,10%)',
  ULTRA_LIGHT_PRIMARY_BLUE: 'rgba(66,106,255,3%)',
  VERY_ULTRA_LIGHT_PRIMARY_BLUE: 'rgba(66,106,255,1%)',
  DARK_PRIMARY_BLUE: '#2336A9',
  // TODO: 준호 - 다음 두 개 컬러명 변경 필요. Patient Button 에서 사용됨
  PATIENT_NORMAL_BUTTON: 'rgba(236, 240, 255, 1)',
  PATIENT_HOVER_BUTTON: 'rgba(173, 190, 255, 1)',

  RED: 'rgba(255,82,82,100%)',
  LIGHT_RED: 'rgba(255,82,82,15%)',
  ULTRA_LIGHT_RED: 'rgba(255,82,82,5%)',

  LIGHT_VIOLET: 'rgba(97, 0, 255, 0.15)',
  LIGHT_VIOLET_HOVER: 'rgba(97, 0, 255, 0.2)',

  SHADOW_BLACK: 'rgba(0,0,0,0.1)',
  // BLACK: '#242626',
  BLACK_FOR_SELECTION: 'rgba(36, 38, 38, 3%)',
  DARK: '#575C5E',
  MEDIUM_DARK: '#8F979D',
  MEDIUM: '#c7ced2',
  MEDIUM_TRANS: 'rgba(199, 206, 210, 0.15)',
  MEDIUM_LIGHT: '#E6EAEC',
  LIGHT: '#F1F3F4',
  ULTRA_LIGHT: '#FAFAFA',
  BLUE: '#426AFF',
  LIGHT_GREY: '#C7CDE2',

  // Class Label Color
  SVE: 'rgba(255,146,45,100%)',
  LIGHT_SVE: 'rgba(255,146,45,10%)',
  SVE_100: 'rgba(255,146,45,20%)', // background
  SVE_400: 'rgba(255,146,45,100%)', // line
  SVE_600: 'rgba(252,95,27,100%)', // text

  VE: 'rgba(22,190,69,100%)',
  LIGHT_VE: 'rgba(22,190,69,10%)',
  VE_100: 'rgba(22,190,69,20%)',
  VE_400: 'rgba(22,190,69,100%)',
  VE_600: 'rgba(0,155,6,100%)',

  AF: 'rgba(255,64,201,100%)',
  LIGHT_AF: 'rgba(255,64,201,10%)',
  AF_100: 'rgba(255,0,214,10%)',
  AF_400: 'rgba(255,64,201,100%)',
  AF_600: 'rgba(223,3,161,100%)',

  AVB: 'rgba(46,188,233,100%)',
  LIGHT_AVB: 'rgba(46,188,233,0.1)',
  AVB_100: 'rgba(46,188,233,10%)',
  AVB_400: 'rgba(46,188,233,100%)',
  AVB_600: 'rgba(0,94,147,100%)',

  PAUSE: 'rgba(110,0,249,100%)',
  PAUSE_100: 'rgba(97,0,255,10%)',
  PAUSE_400: 'rgba(97,0,255,100%)',
  PAUSE_600: 'rgba(88,23,194,100%)',

  OTHERS: 'rgba(87,92,94,100%)',
  LIGHT_OTHERS: 'rgba(87,92,94,10%)',
  OTHERS_100: 'rgba(46,188,233,10%)',
  OTHERS_400: 'rgba(46,188,233,100%)',
  OTHERS_600: 'rgba(0,94,147,100%)',

  QUESTIONABLE: 'rgba(143, 151, 157, 100%)',
  LIGHT_QUESTIONABLE: 'rgba(143, 151, 157, 10%)',
  LEAD_OFF: 'rgba(248, 248, 248, 1)',

  ECG_QUESTIONABLE: 'rgba(199, 206, 210, 1)',
  ECG_BLACK: '#000000',

  TRANSPARENT: 'rgba(0,0,0,0)',

  // https://www.figma.com/file/t0LKh5B4UMRsPhQtsZ7Haq/%F0%9F%97%82%EF%B8%8F-Memo-Web-Design-System?node-id=15255%3A111412&t=e4WP7l1zA6M2ZFjK-4
  // 2023-05-09 (화) 기준
  // BLACK
  BLACK: 'rgba(0,0,0,1)',
  COOL_GRAY_90: 'rgba(31,35,39,1)',
  COOL_GRAY_80: 'rgba(86,90,94,1)',
  COOL_GRAY_70: 'rgba(126,130,134,1)',
  COOL_GRAY_60: 'rgba(166,170,174,1)',
  COOL_GRAY_50: 'rgba(202,205,208,1)',
  COOL_GRAY_40: 'rgba(222,225,228,1)',
  COOL_GRAY_30: 'rgba(237,238,239,1)',
  COOL_GRAY_20: 'rgba(250,250,250,1)',
  WHITE: 'rgba(255,255,255,1)',
  WHITE_DIM: 'rgba(255,255,255,0.5)',
  LIGHT_DIM: 'rgba(0,0,0,0.03)',
  DIM: 'rgba(0,0,0,0.15)',
  BLUE_GRAY: 'rgba(120,144,156,1)',

  // BLUE
  BLUE_100: 'rgba(7,23,121,1)',
  BLUE_90: 'rgba(35,54,169,1)',
  BLUE_80: 'rgba(51,80,212,1)',
  BLUE_70: 'rgba(66,106,255,1)',
  BLUE_60: 'rgba(132,158,255,1)',
  BLUE_50: 'rgba(66,106,255,0.5)',
  BLUE_40: 'rgba(66,106,255,0.25)',
  BLUE_30: 'rgba(66,106,255,0.10)',
  BLUE_20: 'rgba(66,106,255,0.05)',
  BLUE_10: 'rgba(66,106,255,0.01)',

  //COOL_BLUE
  COOL_BLUE: 'rgba(110, 151, 255, 1)',

  // GREEN
  GREEN_90: 'rgba(0,135,121,1)',
  GREEN_80: 'rgba(1,164,148,1)',
  GREEN_70: 'rgba(2,197,178,1)',
  GREEN_60: 'rgba(91,217,205,1)',
  GREEN_50: 'rgba(2,197,178,0.45)',
  GREEN_40: 'rgba(2,197,178,0.25)',
  GREEN_30: 'rgba(2,197,178,0.1)',

  // RED
  RED_100: 'rgba(139,11,11,1)',
  RED_90: 'rgba(180,31,31,1)',
  RED_80: 'rgba(226,58,58,1)',
  RED_70: 'rgba(255,82,82,1)',
  RED_60: 'rgba(255,137,137,1)',
  RED_50: 'rgba(255,82,82,0.45)',
  RED_40: 'rgba(255,82,82,0.25)',
  RED_30: 'rgba(255,82,82,0.1)',

  // AF
  AF_90: 'rgba(182,0,130,1)',
  AF_80: 'rgba(223,3,161,1)',
  AF_70: 'rgba(255,83,201,1)',
  AF_60: 'rgba(255,129,219,1)',
  AF_50: 'rgba(255,83,201,0.45)',
  AF_40: 'rgba(255,83,201,0.25)',

  // SVE
  SVE_90: 'rgba(195,70,0,1)',
  SVE_80: 'rgba(232,70,0,1)',
  SVE_70: 'rgba(248,100,36,1)',
  SVE_60: 'rgba(255,135,91,1)',
  SVE_50: 'rgba(248,100,36,0.45)',
  SVE_40: 'rgba(248,100,36,0.25)',
  SVE_20: 'rgba(248,100,36,0.05)',

  // VE
  VE_90: 'rgba(0,116,5,1)',
  VE_80: 'rgba(6,140,11,1)',
  VE_70: 'rgba(2,159,46,1)',
  VE_60: 'rgba(83,193,114,1)',
  VE_50: 'rgba(2,159,46,0.45)',
  VE_40: 'rgba(2,159,46,0.25)',
  VE_20: 'rgba(2,159,46,0.05)',

  // PAUSE
  PAUSE_90: 'rgba(85,2,168,1)',
  PAUSE_80: 'rgba(110,26,204,1)',
  PAUSE_70: 'rgba(147,53,255,1)',
  PAUSE_60: 'rgba(176,124,255,1)',
  PAUSE_50: 'rgba(147,39,255,0.45)',
  PAUSE_40: 'rgba(147,39,255,0.25)',
  LIGHT_PAUSE: 'rgba(110,0,249,0.1)',

  // OTHERS
  OTHERS_90: 'rgba(11,125,161,1)',
  OTHERS_80: 'rgba(0,155,200,1)',
  OTHERS_70: 'rgba(33,182,231,1)',
  OTHERS_60: 'rgba(95,206,241,1)',
  OTHERS_50: 'rgba(33,194,244,0.45)',
  OTHERS_40: 'rgba(33,193,244,0.25)',
  OTHERS_30: 'rgba(33,193,244,0.15)',
  OTHERS_20: 'rgba(33,193,244,0.05)',

  TOP_ALERT_BACKGROUND: 'rgba(255,246,209,1)',

  // AVB
  AVB_90: 'rgba(171,111,0,1)',
  AVB_80: 'rgba(208,135,0,1)',
  AVB_70: 'rgba(230,168,2,1)',
  AVB_60: 'rgba(237,202,89,1)',
  AVB_50: 'rgba(237,202,89,0.45)',
  AVB_40: 'rgba(237,202,89,0.25)',
};

export default AppColors;
