import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import DateUtil from 'util/DateUtil';

export const SHIHWA_HOSPITAL = {
  HOSPITAL_ID: 404,
  isShihwaHospital: (hid: number) => hid === SHIHWA_HOSPITAL.HOSPITAL_ID,
  /**
   * 요구사항 : https://huinno.atlassian.net/browse/MC-5513
   *
   * 시화병원 측에서 ‘JPG 다운로드’ 버튼을 클릭하면, 아래와 같은 형태로 ZIP 파일을 생성·다운로드합니다.
   * zip 파일 이름 : ‘{환자번호}_{다운로드날짜}.zip’
   * zip 내부 개별 JPG 파일 이름 : ’{환자번호}{다운로드날짜}_{현재인덱스}of{전체개수}.jpg’
   *
   * 예를 들어, 환자번호가 P12345, 다운로드 날짜가 2025-02-06(목)이며, JPG 파일이 총 3장이라면 다음과 같은 파일 구조가 생성됩니다.  다운로드할 때마다 (‘JPG 다운로드’ 버튼을 클릭한 시점의 날짜)를 기준으로 파일명이 자동으로 생성됩니다.
   *
   * 1. ‘JPG 다운로드’ 버튼 클릭 시
   * → P12345_20250206.zip 파일 다운로드
   *
   * 2. 해당 ZIP 파일을 압축 해제하면,
   * • P12345_20250206_001of003.jpg
   * • P12345_20250206_002of003.jpg
   * • P12345_20250206_003of003.jpg
   * 세 개의 JPG 파일이 들어 있습니다.
   *
   * @param {string} zipUrl - 압축파일이 위치한 URL (예: presigned URL)
   * @param {string} patientNumber - 환자번호 (예: "P12345")
   */
  downloadAndRenameZipFile: async (zipUrl: string, patientNumber: string) => {
    try {
      // 1. zip 파일 다운로드
      const response = await fetch(zipUrl);
      if (!response.ok) {
        alert(
          '압축파일 다운로드에 실패했습니다. 휴이노 고객센터 (02-3443-3160)로 문의해주세요.'
        );
        return;
      }
      const arrayBuffer = await response.arrayBuffer();

      // 2. 다운로드한 파일을 JSZip으로 로드
      const zip = await JSZip.loadAsync(arrayBuffer);

      // 3. 새 zip 인스턴스 생성 (이곳에 이름을 변경한 파일들을 추가)
      const newZip = new JSZip();

      // 4. 실제 파일(디렉토리 제외)만 추출
      const fileEntries = Object.entries(zip.files).filter(
        ([, file]) => !file.dir
      );
      const totalFiles = fileEntries.length;

      // 5. 각 파일에 대해 이름 재구성 후 새 zip에 추가
      const filePromises = fileEntries.map(async ([filename, file], index) => {
        const fileData = await file.async('arraybuffer');
        // index는 0부터 시작하므로 +1
        const newFilename = renameMapping(filename, index + 1, totalFiles);
        newZip.file(newFilename, fileData);
      });
      await Promise.all(filePromises);

      // 6. 새 zip 파일을 Blob으로 생성하고 다운로드
      const newZipBlob = await newZip.generateAsync({ type: 'blob' });
      saveAs(newZipBlob, `${patientNumber}_${getFormattedDate()}.zip`);
    } catch (error) {
      console.error('압축파일 처리 에러:', error);
    }

    // 현재 날짜를 YYYYMMDD 형식으로 반환하는 내부 함수
    function getFormattedDate() {
      const today = DateUtil.getUserLocationTime();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}${month}${day}`;
    }

    // 파일 이름을 재구성하는 내부 함수
    // originalName: 원본 파일 경로/이름, fileIndex: 순번(1부터 시작), totalFiles: 총 파일 수
    function renameMapping(
      originalName: string,
      fileIndex: number,
      totalFiles: number
    ) {
      // 경로가 포함되어 있다면 마지막 요소(파일명)만 추출
      const parts = originalName.split('/');
      const fileName = parts.pop();

      // 파일명에서 확장자 분리
      const dotIndex = fileName!.lastIndexOf('.');
      const extension = dotIndex !== -1 ? fileName!.substring(dotIndex) : '';

      // 순번과 총 개수를 3자리 숫자로 포맷팅
      const paddedIndex = String(fileIndex).padStart(3, '0');
      const paddedTotal = String(totalFiles).padStart(3, '0');

      return `${patientNumber}_${getFormattedDate()}_${paddedIndex}of${paddedTotal}${extension}`;
    }
  },
};
