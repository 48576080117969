import { defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';

import Const, { TEST_STATUS_ARRAY as _TEST_STATUS_ARRAY } from 'constant/Const';

import DateUtil from 'util/DateUtil';

import { TableRow, TableData } from 'component/ui/table/Table';
import Tooltip from 'component/ui/tooltip/Tooltip';
import {
  CommentDefaultIcon,
  CommentIcon,
  NotificationIcon,
} from 'component/ui/icons';

import EcgTestDataItem from './ecgTestDataItem/EcgTestDataItem';
import ReturnTypeButton from './ReturnTypeButton';
import { EstimatedEndAndReturnDateTime } from './EcgTestListFragment';

const { ECG_TEST_STATUS } = Const;
const TEST_STATUS_ARRAY = _TEST_STATUS_ARRAY;

function TableRowData({
  ecgTest,
  selectedTid,
  index,
  validReportPending,
  medicalStaffsFetchPending,
  isUseParcel,
  ecgTestsFetchState,
  handleShowSidePanel,
  handleShowDialog,
  handleRevertStatusRequested,
  loadPageData,
}) {
  const {
    tid,
    patientNumber,
    patientName,
    patientBirth,
    deviceNumber,
    shortTid,
    ecgTestStatus,
    estimatedEndDatetime,
    returnDatetime,
    prescriptionDuration,
    userTestNotification,
    note,
    startDatetime,
    parcel,
    isMemoCueEcgTest,
  } = ecgTest;

  return (
    <TableRow
      selected={selectedTid === tid}
      showBorderBottom={index < ecgTestsFetchState.data.length - 1}
      isHospitalUserReadNotification={
        userTestNotification?.isHospitalUserReadNotification
      }
      onClick={() => {
        handleShowSidePanel({ ecgTestId: tid });
      }}>
      {isUseParcel && (
        <StyledTableData maxWidth={75}>
          <ReturnTypeButton ecgTest={ecgTest} parcel={parcel} />
        </StyledTableData>
      )}

      <StyledTableData>{patientNumber}</StyledTableData>

      <StyledTableData>
        <PatientName
          name={patientName}
          note={note}
          isHospitalUserReadNotification={
            userTestNotification?.isHospitalUserReadNotification
          }
          isNotificationSentToHospitalUser={
            userTestNotification?.isNotificationSentToHospitalUser
          }
        />
      </StyledTableData>

      <StyledTableData>{patientBirth}</StyledTableData>

      <StyledTableData>{deviceNumber || '-'}</StyledTableData>
      <StyledTableData>
        <StyledTableDataForMemoCueLabel>
          <>{shortTid}</>
          <>
            {isMemoCueEcgTest && (
              <Tooltip
                maxWidth={160}
                title={'MEMO Cue에서 생성된 검사'}
                placement="bottom">
                <EcgTestFromCueWrapper isSidePanelWrapper={false}>
                  <EcgTestFromCueText>Cue</EcgTestFromCueText>
                </EcgTestFromCueWrapper>
              </Tooltip>
            )}
          </>
        </StyledTableDataForMemoCueLabel>
      </StyledTableData>
      <StyledTableData
        style={{
          paddingLeft: 0,
        }}>
        <StatusBadge
          ecgTestStatus={ecgTestStatus}
          estimatedEndDatetime={estimatedEndDatetime}
        />
      </StyledTableData>

      <StyledTableData>
        <EcgTestDataItem
          ecgTest={ecgTest}
          validReportPending={validReportPending}
          medicalStaffsFetchPending={medicalStaffsFetchPending}
          isSidePanel={false}
          handleShowDialog={handleShowDialog}
          handleRevertStatusRequested={handleRevertStatusRequested}
          loadPageData={loadPageData}
        />
      </StyledTableData>

      <StyledTableData>
        <PrescriptionDuration prescriptionDuration={prescriptionDuration} />
      </StyledTableData>

      <StyledTableData>
        {startDatetime ? DateUtil.formatDateOnly(startDatetime) : '-'}
      </StyledTableData>

      <StyledTableData>
        <EstimatedEndAndReturnDateTime
          estimatedEndDatetime={estimatedEndDatetime}
          returnDatetime={returnDatetime}
        />
      </StyledTableData>
    </TableRow>
  );
}

function StatusBadge({ ecgTestStatus, estimatedEndDatetime }) {
  const intl = useIntl();

  let { label, color, background } =
    _findTestStatusByEcgTestStatus(ecgTestStatus);

  if (
    ecgTestStatus === ECG_TEST_STATUS.WAITING_UPLOAD &&
    estimatedEndDatetime
  ) {
    label = (
      <>
        {label}(
        {DateUtil.calcDday(estimatedEndDatetime, {
          prefixSign: true,
        })}
        {intl.formatMessage(INTL_MAP.DAY)})
      </>
    );
  }

  return (
    <EcgTestStatusWrapper>
      <EcgTestStatusText color={color} background={background}>
        {label}
      </EcgTestStatusText>
    </EcgTestStatusWrapper>
  );
}

function PatientName({
  note,
  name,
  isHospitalUserReadNotification,
  isNotificationSentToHospitalUser,
}) {
  const modifiedNote =
    note?.length > 100 ? `${note.substring(0, 100)} ...` : note;

  return (
    <PatientNameWrapper>
      <PatientNameText>{name}</PatientNameText>
      <IconWrapper>
        {isNotificationSentToHospitalUser && (
          <NotificationIconWrapper
            isHospitalUserReadNotification={isHospitalUserReadNotification}>
            <NotificationIcon />
          </NotificationIconWrapper>
        )}
        <Tooltip title={modifiedNote} placement="bottom">
          {note ? <StyledCommentIcon /> : <StyledCommentDefaultIcon />}
        </Tooltip>
      </IconWrapper>
    </PatientNameWrapper>
  );
}

function PrescriptionDuration({ prescriptionDuration }) {
  const intl = useIntl();

  const formattedText =
    prescriptionDuration > 1
      ? intl.formatMessage(INTL_MAP.DAYS_UNIT, {
          days: prescriptionDuration,
        })
      : intl.formatMessage(INTL_MAP.DAY_UNIT, {
          day: prescriptionDuration,
        });

  const FEE_CODE = {
    E6545: 'E6545',
    E6556: 'E6556',
    E657: 'E657',
  };
  const PRESCRIPTION_RANGES = [
    { code: FEE_CODE.E6545, min: 0, max: 2 },
    { code: FEE_CODE.E6556, min: 3, max: 7 },
    { code: FEE_CODE.E657, min: 8, max: 14 },
  ];

  const getFeeCode = (duration) =>
    PRESCRIPTION_RANGES.find(
      (range) => duration >= range.min && duration <= range.max
    )?.code ?? null;

  const feeCode = getFeeCode(prescriptionDuration);

  return (
    <PrescriptionDurationWrapper feeCode={feeCode} FEE_CODE={FEE_CODE}>
      <PrescriptionDurationText feeCode={feeCode} FEE_CODE={FEE_CODE}>
        {formattedText}
      </PrescriptionDurationText>
    </PrescriptionDurationWrapper>
  );
}

// utils function
function _findTestStatusByEcgTestStatus(ecgTestStatus) {
  const result = TEST_STATUS_ARRAY.find((status) =>
    status.value.includes(ecgTestStatus)
  );
  if (!result) return { label: '', color: '', background: '' };

  return result;
}

const StyledCommentIcon = styled(CommentIcon)`
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  flex-shrink: 0;
`;
const StyledCommentDefaultIcon = styled(CommentDefaultIcon)`
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  flex-shrink: 0;
`;

const PatientNameWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media screen and (min-width: 1920px) {
    padding-right: 10px;
  }
`;

const PatientNameText = styled.div`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
`;

const EcgTestStatusWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const EcgTestStatusText = styled.div`
  color: ${({ theme, color }) => theme.color[color]};
  background: ${({ theme, background }) => theme.color[background]};
  font-weight: 500;
  border-radius: 2px;
  padding: 1px 4px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px 4px;
  line-height: 1;
`;

export const EcgTestFromCueWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 2px;
  margin-left: ${({ isSidePanelWrapper }) =>
    isSidePanelWrapper ? '8px' : '4px'};
`;

export const EcgTestFromCueText = styled.div`
  color: ${({ theme }) => theme.color.WHITE};
  background-color: ${({ theme }) => theme.color.COOL_BLUE};
  font-weight: 500;
  border-radius: 2px;
  padding: 1px 4px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 3px;
  line-height: 13;
  font-size: 10px;
`;

export const StyledTableData = styled(TableData)`
  box-sizing: border-box;
`;
const StyledTableDataForMemoCueLabel = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-right: 40px;
`;

const IconWrapper = styled.div`
  display: flex;
  gap: 5px;
`;

const NotificationIconWrapper = styled.div`
  height: 20px;
  svg {
    width: 20px;
    height: 20px;
  }
  path {
    fill: ${({ theme, isHospitalUserReadNotification }) =>
      isHospitalUserReadNotification
        ? theme.color.COOL_GRAY_80
        : theme.color.SVE_70};
  }
`;

const PrescriptionDurationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  width: 44px;
  padding: 1px 8px;
  border-radius: 100px;
  background-color: ${({ theme, feeCode, FEE_CODE }) => {
    if (feeCode === FEE_CODE.E6545) return theme.color.BLUE_20;
    if (feeCode === FEE_CODE.E6556) return theme.color.OTHERS_20;
    if (feeCode === FEE_CODE.E657) return theme.color.VE_20;
    return theme.color.WHITE;
  }};
`;
const PrescriptionDurationText = styled.div`
  font-weight: 500;
  color: ${({ theme, feeCode, FEE_CODE }) => {
    if (feeCode === FEE_CODE.E6545) return theme.color.BLUE_70;
    if (feeCode === FEE_CODE.E6556) return theme.color.OTHERS_90;
    if (feeCode === FEE_CODE.E657) return theme.color.VE_80;
    return theme.color.COOL_GRAY_90;
  }};
`;

const INTL_MAP = defineMessages({
  DAY: {
    id: '09-GeneralFragment-SectionVerticalItem-value-days',
    description: '처방 기간',
    defaultMessage: '일',
  },
  DAYS_UNIT: {
    id: '02-MainPage-TableData-days-unit',
    description: '2일, 3일, ... (기간을 나타냄)',
    defaultMessage: '{days}일',
  },
  DAY_UNIT: {
    id: '02-MainPage-TableData-day-unit',
    description: '0일, 1일 (기간을 나타냄)',
    defaultMessage: '{day}일',
  },
});

export default TableRowData;
